.dashboard {
  min-height: 100vh;
  td,
  th {
    text-align: center;
  }

  .table td {
    text-align: left;
  }
}

pre {
  text-align: left;
}

thead {
  overflow-y: auto;
  height: 100px;
}
th {
  position: sticky;
  top: 0;
  background: #fff;
}
